import React, { useState, useEffect } from "react";
// import "./home.css"
import axiosapi from "../api/axiosapi";
import { Link } from "react-router-dom";
import SideMenu from "../components/SideMenu";
// import ProblemStatDescription from "../components/ProblemStatDescription";
import Footer from "../components/Footer";
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';
import { Helmet } from 'react-helmet';

const splitContent = (sentence) => {
    const InlineMathRegex = /<InlineMath[^>]*>(.*?)<\/InlineMath>/g;
    const matches = sentence.matchAll(InlineMathRegex);
    let lastIndex = 0;
    const contentArray = [];
  
    for (const match of matches) {
        const InlineMathContent = <InlineMath key={lastIndex} math={match[1]} />;
        const restOfContent = sentence.substring(lastIndex, match.index);
    
        contentArray.push(restOfContent, InlineMathContent);
        lastIndex = match.index + match[0].length;
    }
  
    if (lastIndex < sentence.length) {
        contentArray.push(sentence.substring(lastIndex));
    }
  
    return <div>{contentArray}</div>;
};

const Home = () => {
    const [todayProblemList, setTodayProblemList] = useState([]);
    // const [dataList, setDataList] = useState([]);
    const [imageList, setImageList] = useState([]);
    const [imageProblem, setImageProblem] = useState();
    const [improveMathAbilityBeginner, setImproveMathAbilityBeginner] = useState([]);
    const [improveMathAbilityEasy, setImproveMathAbilityEasy] = useState([]);
    const [improveMathAbilityMedium, setImproveMathAbilityMedium] = useState([]);
    const [improveMathAbilityHard, setImproveMathAbilityHard] = useState([]);
    const [improveMathAbilityVeryHard, setImproveMathAbilityVeryHard] = useState([]);
    // const [subject, setSubject] = useState([]);
    const [errPT, setErrPT] = useState();
    // const [errTMA, setErrTMA] = useState();
    // const [errSub, setErrSub] = useState();
    const [err2, setErr2] = useState();
    // const [randomString1, setRandomString1] = useState();
    // const [randomString2, setRandomString2] = useState();
    // const [randomString3, setRandomString3] = useState();
    // const [randomString4, setRandomString4] = useState();
    // const [randomString5, setRandomString5] = useState();
    // const [randomString, setRandomString] = useState();
    // const [idString, setIdString] = useState('')

    useEffect(() => {
        const controller = new AbortController();

        const fetchTodayProblem = async () => {
            try {
                const response = await axiosapi.get('/home/hometodayproblem/')
                response.data && setTodayProblemList(response.data.todayProblemList);
                response.data && response.data.imageList.length > 0 && setImageList(response.data.imageList);
                response.data && setImageProblem(response.data.imageProblem);
            } catch (err) {
                setErrPT(err.message);
                if (err.response) {
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchTodayProblem();

        const fetchImproveAbility = async () => {
            try {
                const response2 = await axiosapi.get('/home/homeimproveability/')
                response2.data && setImproveMathAbilityBeginner(response2.data.beginner); 
                response2.data && setImproveMathAbilityEasy(response2.data.easy); 
                response2.data && setImproveMathAbilityMedium(response2.data.medium);
                response2.data && setImproveMathAbilityHard(response2.data.hard);
                response2.data && setImproveMathAbilityVeryHard(response2.data.veryHard);
            } catch (err2) {
                setErr2(err2);
            }
        }
        fetchImproveAbility();

        return () => controller.abort();
    },[])

    // 텍스트를 50 자로 제한하는 함수
    const truncateText = (text, limit) => {
        return text.length > limit ? text.substring(0, limit) + '...' : text;
    };

    return(
        <div className="App-full-container">
            <div className="App-full-row-container-1">
                <Helmet>
                    <meta property="og:title" content="티프매쓰" />
                    <meta property="og:description" content="안녕하세요. 티프매쓰입니다." />
                    {/* <meta property="og:image" content="https://example.com/thumbnail.jpg" />
                    <meta property="og:url" content="https://example.com" />
                    <meta property="og:type" content="website" /> */}
                </Helmet>
                <div className="content-box-1">
                    <div className="vertical-flex-container-1">
                        <div className="position-center-box-2-1">
                            <h4 style={{color:"sienna", fontWeight:"bold"}}>도전!!! 작도 퀴즈</h4>
                            <p>작도 퀴즈와 함께 당신의 잠자고 있는 뇌를 깨우세요. 목표가 되는 도형을 상상하고, 직관을 통해 구체화하고, 기하 지식과 논리를 통해 체계화해 작도 퀴즈를 해결하는 짜릿한 지적 쾌감을 느껴보세요.</p>
                        </div>

                        <div className="position-center-box-2-1" style={{marginBottom:"15px"}}>
                            <div className="horizontal-flex-container">
                                <div className="position-center-box-2-2"> 
                                    <div style={{width: '100%', height: '300px', backgroundImage: `url('https://tiffmath.com/image/beginner.png')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
                                        {improveMathAbilityBeginner.slice(0,1).map(improveAbility => {
                                            return(
                                                <div key={improveAbility.id} className="improveAbilityImageContainer">
                                                    <Link
                                                        to={`/door/brainup/${improveAbility.categoryCode}`}
                                                        // state={{
                                                        //     "categoryCode": improveAbility.categoryCode,
                                                        //     "problemCode": improveAbility.firstProblemCode,
                                                        //     "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                                        //     // "improveAbilityList": improveMathAbilityBeginner,
                                                        //     "improveAbilityType": "beginner"
                                                        // }}
                                                    >
                                                        <h6 style={{paddingTop: "100px", paddingRight:"10px", textAlign:"right", color:"black", fontWeight:"bolder", fontSize:"21px"}}>{improveAbility.improveAbilityTitle}</h6>
                                                        <p style={{paddingTop: "25px", paddingLeft: "70px", paddingRight:"10px", textAlign:"right", color:"black", fontWeight:"bolder"}}>{truncateText(improveAbility.improveAbilityDescription, 50)}</p>
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="position-center-box-2-2">
                                    {improveMathAbilityBeginner.slice(1,2).map(improveAbility => {
                                        return(
                                            <div key={improveAbility.id} className="mt-1 mb-3">
                                                <Link
                                                    to={`/door/brainup/${improveAbility.categoryCode}`}
                                                    // state={{
                                                    //     "categoryCode": improveAbility.categoryCode,
                                                    //     "problemCode": improveAbility.firstProblemCode,
                                                    //     "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                                    //     // "improveAbilityList": improveMathAbilityBeginner,
                                                    //     "improveAbilityType": "beginner"
                                                    // }}
                                                >
                                                    <h6 style={{fontWeight:"bolder", fontSize:"17px"}}>{improveAbility.improveAbilityTitle}</h6>
                                                    <p style={{color:"black", fontWeight:"normal"}}>{improveAbility.improveAbilityDescription}</p>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                    <div style={{marginTop:"20px"}}>
                                        <Link 
                                            to="/improvemathability"
                                            state={{
                                                "improveAbilityType": "beginner"
                                            }}
                                        >
                                            더 보기
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="position-center-box-2-1" style={{marginBottom:"15px"}}>
                            <div className="horizontal-flex-container">
                                <div className="position-center-box-2-2">
                                    <div style={{width: '100%', height: '300px', backgroundImage: `url('https://tiffmath.com/image/easy.png')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
                                        {improveMathAbilityEasy.slice(0,1).map(improveAbility => {
                                            return(
                                                <div key={improveAbility.id} className="improveAbilityImageContainer">
                                                    <Link
                                                        to={`/door/brainup/${improveAbility.categoryCode}`}
                                                        // state={{
                                                        //     "categoryCode": improveAbility.categoryCode,
                                                        //     "problemCode": improveAbility.firstProblemCode,
                                                        //     "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                                        //     // "improveAbilityList": improveMathAbilityEasy,
                                                        //     "improveAbilityType": "easy"
                                                        // }}
                                                    >
                                                        <h6 style={{paddingTop: "100px", paddingRight:"10px", textAlign:"right", color:"black", fontWeight:"bolder", fontSize:"21px"}}>{improveAbility.improveAbilityTitle}</h6>
                                                        <p style={{paddingTop: "25px", paddingLeft: "70px", paddingRight:"10px", textAlign:"right", color:"black", fontWeight:"bolder"}}>{truncateText(improveAbility.improveAbilityDescription, 50)}</p>
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="position-center-box-2-2">
                                    {improveMathAbilityEasy.slice(1,2).map(improveAbility => {
                                        return(
                                            <div key={improveAbility.id} className="mt-1 mb-3">
                                                <Link
                                                    to={`/door/brainup/${improveAbility.categoryCode}`}
                                                    // state={{
                                                    //     "categoryCode": improveAbility.categoryCode,
                                                    //     "problemCode": improveAbility.firstProblemCode,
                                                    //     "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                                    //     // "improveAbilityList": improveMathAbilityEasy,
                                                    //     "improveAbilityType": "easy"
                                                    // }}
                                                >
                                                    <h6 style={{fontWeight:"bolder", fontSize:"17px"}}>{improveAbility.improveAbilityTitle}</h6>
                                                    <p style={{color:"black", fontWeight:"normal"}}>{improveAbility.improveAbilityDescription}</p>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                    <div style={{marginTop:"20px"}}>
                                        <Link 
                                            to="/improvemathability"
                                            state={{
                                                "improveAbilityType": "easy"
                                            }}
                                        >
                                            더 보기
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="position-center-box-2-1" style={{marginBottom:"15px"}}>
                            <div className="horizontal-flex-container">
                                <div className="position-center-box-2-2">
                                    <div style={{width: '100%', height: '300px', backgroundImage: `url('https://tiffmath.com/image/medium.png')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
                                        {improveMathAbilityMedium.slice(0,1).map(improveAbility => {
                                            return(
                                                <div key={improveAbility.id} className="improveAbilityImageContainer">
                                                    <Link
                                                        to={`/door/brainup/${improveAbility.categoryCode}`}
                                                        // state={{
                                                        //     "categoryCode": improveAbility.categoryCode,
                                                        //     "problemCode": improveAbility.firstProblemCode,
                                                        //     "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                                        //     // "improveAbilityList": improveMathAbilityMedium,
                                                        //     "improveAbilityType": "medium"
                                                        // }}
                                                    >
                                                        <h6 style={{paddingTop: "100px", paddingRight:"10px", textAlign:"right", color:"black", fontWeight:"bolder", fontSize:"21px"}}>{improveAbility.improveAbilityTitle}</h6>
                                                        <p style={{paddingTop: "25px", paddingLeft: "70px", paddingRight:"10px", textAlign:"right", color:"black", fontWeight:"bolder"}}>{truncateText(improveAbility.improveAbilityDescription, 50)}</p>
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="position-center-box-2-2">
                                    {improveMathAbilityMedium.slice(1,2).map(improveAbility => {
                                        return(
                                            <div key={improveAbility.id} className="mt-1 mb-3">
                                                <Link
                                                    to={`/door/brainup/${improveAbility.categoryCode}`}
                                                    // state={{
                                                    //     "categoryCode": improveAbility.categoryCode,
                                                    //     "problemCode": improveAbility.firstProblemCode,
                                                    //     "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                                    //     // "improveAbilityList": improveMathAbilityMedium,
                                                    //     "improveAbilityType": "medium"
                                                    // }}
                                                >
                                                    <h6 style={{fontWeight:"bolder", fontSize:"17px"}}>{improveAbility.improveAbilityTitle}</h6>
                                                    <p style={{color:"black", fontWeight:"normal"}}>{improveAbility.improveAbilityDescription}</p>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                    <div style={{marginTop:"20px"}}>
                                        <Link 
                                            to="/improvemathability"
                                            state={{
                                                "improveAbilityType": "medium"
                                            }}
                                        >
                                            더 보기
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="position-center-box-2-1" style={{marginBottom:"15px"}}>
                            <div className="horizontal-flex-container">
                                <div className="position-center-box-2-2">
                                    <div style={{width: '100%', height: '300px', backgroundImage: `url('https://tiffmath.com/image/hard.png')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
                                        {improveMathAbilityHard.slice(0,1).map(improveAbility => {
                                            return(
                                                <div key={improveAbility.id} className="improveAbilityImageContainer">
                                                    <Link
                                                        to={`/door/brainup/${improveAbility.categoryCode}`}
                                                        // state={{
                                                        //     "categoryCode": improveAbility.categoryCode,
                                                        //     "problemCode": improveAbility.firstProblemCode,
                                                        //     "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                                        //     // "improveAbilityList": improveMathAbilityHard,
                                                        //     "improveAbilityType": "hard"
                                                        // }}
                                                    >
                                                        <h6 style={{paddingTop: "100px", paddingRight:"10px", textAlign:"right", color:"black", fontWeight:"bolder", fontSize:"21px"}}>{improveAbility.improveAbilityTitle}</h6>
                                                        <p style={{paddingTop: "25px", paddingLeft: "70px", paddingRight:"10px", textAlign:"right", color:"black", fontWeight:"bolder"}}>{truncateText(improveAbility.improveAbilityDescription, 50)}</p>
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="position-center-box-2-2">
                                    {improveMathAbilityHard.slice(1,2).map(improveAbility => {
                                        return(
                                            <div key={improveAbility.id} className="mt-1 mb-3">
                                                <Link
                                                    to={`/door/brainup/${improveAbility.categoryCode}`}
                                                    // state={{
                                                    //     "categoryCode": improveAbility.categoryCode,
                                                    //     "problemCode": improveAbility.firstProblemCode,
                                                    //     "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                                    //     // "improveAbilityList": improveMathAbilityHard,
                                                    //     "improveAbilityType": "hard"
                                                    // }}
                                                >
                                                    <h6 style={{fontWeight:"bolder", fontSize:"17px"}}>{improveAbility.improveAbilityTitle}</h6>
                                                    <p style={{color:"black", fontWeight:"normal"}}>{improveAbility.improveAbilityDescription}</p>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                    <div style={{marginTop:"20px"}}>
                                        <Link 
                                            to="/improvemathability"
                                            state={{
                                                "improveAbilityType": "hard"
                                            }}
                                        >
                                            더 보기
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="position-center-box-2-1" style={{marginBottom:"15px"}}>
                            <div className="horizontal-flex-container">
                                <div className="position-center-box-2-2">
                                    <div style={{width: '100%', height: '300px', backgroundImage: `url('https://tiffmath.com/image/veryhard.png')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
                                        {improveMathAbilityVeryHard.slice(0,1).map(improveAbility => {
                                            return(
                                                <div key={improveAbility.id} className="improveAbilityImageContainer">
                                                    <Link
                                                        to={`/door/brainup/${improveAbility.categoryCode}`}
                                                        // state={{
                                                        //     "categoryCode": improveAbility.categoryCode,
                                                        //     "problemCode": improveAbility.firstProblemCode,
                                                        //     "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                                        //     // "improveAbilityList": improveMathAbilityVeryHard,
                                                        //     "improveAbilityType": "veryHard"
                                                        // }}
                                                    >
                                                        <h6 style={{paddingTop: "100px", paddingRight:"10px", textAlign:"right", color:"black", fontWeight:"bolder", fontSize:"21px"}}>{improveAbility.improveAbilityTitle}</h6>
                                                        <p style={{paddingTop: "25px", paddingLeft: "70px", paddingRight:"10px", textAlign:"right", color:"black", fontWeight:"bolder"}}>{truncateText(improveAbility.improveAbilityDescription, 50)}</p>
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="position-center-box-2-2">
                                    {improveMathAbilityVeryHard.slice(1,2).map(improveAbility => {
                                        return(
                                            <div key={improveAbility.id} className="mt-1 mb-3">
                                                <Link
                                                    to={`/door/brainup/${improveAbility.categoryCode}`}
                                                    // state={{
                                                    //     "categoryCode": improveAbility.categoryCode,
                                                    //     "problemCode": improveAbility.firstProblemCode,
                                                    //     "improveAbilityTitle": improveAbility.improveAbilityTitle,
                                                    //     // "improveAbilityList": improveMathAbilityVeryHard,
                                                    //     "improveAbilityType": "veryHard"
                                                    // }}
                                                >
                                                    <h6 style={{fontWeight:"bolder", fontSize:"17px"}}>{improveAbility.improveAbilityTitle}</h6>
                                                    <p style={{color:"black", fontWeight:"normal"}}>{improveAbility.improveAbilityDescription}</p>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                    <div style={{marginTop:"20px"}}>
                                        <Link 
                                            to="/improvemathability"
                                            state={{
                                                "improveAbilityType": "veryHard"
                                            }}
                                        >
                                            더 보기
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="vertical-flex-container-1">
                        <div className="position-center-box-2-1" style={{marginTop:"15px"}}>
                            <h4 style={{color:"sienna", fontWeight:"bold"}}>즐거운 작도 세상</h4>
                            <p>작도 문제를 해결하고 숨겨진 생각을 그림으로 발산하세요. 솔직하고 어설픈 그림도 크게 들린답니다.</p>
                            {todayProblemList.slice(0,1).map(todayProblem => {
                                return(
                                    <div key={todayProblem.id}>
                                        <Link 
                                            to={`/todayproblem/${todayProblem.urlCode}`}
                                            state={{
                                                "todayProblemList": todayProblemList,
                                                "todayProblem": todayProblem,
                                                // "imageList": imageList
                                            }}
                                        >
                                            <div style={{textAlign:"center"}}>{splitContent(todayProblem.todayProblemTitle)}</div>
                                            <img src={`/image/${todayProblem.imageFile}`} style={{width:'95%', backgroundColor:`${todayProblem.backgroundColor}`}} />
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="position-center-box-2-1">
                            <div className="horizontal-flex-container">
                                <div className="position-center-box-2-2">
                                    <h5 style={{marginTop:'25px', marginBottom:'18px', fontWeight:"bold"}}>그림이 와글와글</h5>
                                    {imageList.map(image => {
                                        return(
                                            <div key={image.id}>
                                                <Link 
                                                    to={`/viewimage/${image.imageFile}/${image.backgroundColor}`}
                                                    // state={{
                                                    //     "imageFileName": image.imageFile,
                                                    //     "imageBackgroundColor": image.backgroundColor
                                                    // }}
                                                    target="_blank"
                                                >
                                                    <p style={{marginLeft:'10px'}}>{image.coloringTitle}</p>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </div>

                                <div className="position-center-box-2-2">
                                    <h5 style={{marginTop:'25px', marginBottom:'18px', fontWeight:"bold"}}>다른 작도 문제</h5>
                                    {todayProblemList.slice(1,todayProblemList.length).map(todayProblem => {
                                        return (
                                            <div key={todayProblem.id}>
                                                <Link 
                                                    to={`/todayproblem/image/${todayProblem.urlCode}`}
                                                    state={{
                                                        // "todayProblemList": todayProblemList,
                                                        "todayProblem": todayProblem
                                                    }}
                                                >
                                                    <div>{splitContent(todayProblem.todayProblemTitle)}</div>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="left-menu-box-1">
                    <div style={{marginTop: "9px"}}>
                        <SideMenu />
                    </div>
                </div>
            </div>
            <div style={{width:"100%"}}>
                <Footer/>
            </div>
            
        </div>
    );
}

export default Home;
