import Home from './pages/Home';
import TodayProblemList from './pages/TodayProblemList';
import TodayProblem from './pages/TodayProblem';
import MathSubjectList from './pages/MathSubjectList';
// import MathSubject from './pages/MathSubject';
import ImproveMathAbilityList from './pages/ImproveMathAbilityList';
import ImproveMathAbility from './pages/ImproveMathAbility';
// import TodayProblemMy from './pages/TodayProblemMy';
// import ImproveMathAbilityMy from './pages/improveMathAbilityMy';
import GradeList from './pages/GradeList';
// import MathSubjectMy from './pages/MathSubjectMy';
import GradeFirstProblem from './pages/GradeFirstProblem';
// import TodayProblemMySolAnimation from './pages/TodayProblemMySolAnimation';
// import ImproveMathAbilityMySolAnimation from './pages/ImproveMathAbilityMySolAnimation';
// import MathSubjectMySolAnimation from './pages/MathSubjectMySolAnimation';
import Login from './pages/Login';
import Unauthorized from './pages/UnAuthorized';
import SignUp from './pages/SignUp';
import FreeAgreement from './pages/FreeAgreement';
import ForgotIdFirst from './pages/ForgotIdFirst';
import ForgotIdSecondEmail from './pages/ForgotIdSecondEmail';
import ForgotIdThird from './pages/ForgotIdThird';
import ForgotIdSecondMobile from './pages/ForgotIdSecondMobile';
import ForgotPwdFirst from './pages/ForgotPwdFirst';
import ForgotPwdSecond from './pages/ForgotPwdSecond';
import GeometricColoringList from './pages/GeometricColoringList';
// import GeometricColoring from './pages/GeometricColoring';
import ExerciseCalculationList from './pages/ExerciseCalculationList'
import ViewImage from './pages/ViewImage';
import TodayProblemImage from './pages/TodayProblemImage';
import TodayProblemFromImage from './pages/TodayProblemFromImage';
import TodayProblemImageList from './pages/TodayProblemImageList';
import MathSubjectImageList from './pages/MathSubjectImageList';
import GradeTotalListBrainUp from './pages/GradeTotalListBrainUp';
import GradeTotalListTextbook from './pages/GradeTotalListTextbook';
import DoorTodayProblem from './pages/DoorTodayProblem';
import DoorBrainUp from './pages/DoorBrainUp';
import DoorProblem from './pages/DoorProblem';
import ViewPrivacyPolicy from './pages/ViewPrivacyPolicy';
import ViewFreemembershipAgreement from './pages/ViewFreemembershipAgreement';
import ViewPremiumServiceAgreement from './pages/ViewPremuimServiceAgreement';
import ViewCopyright from './pages/ViewCopyright';
// import ExerciseCalculationSample from './pages/ExerciseCalculationSample';


export default [
    {
        path:'/',
        element: Home,
        required: true
    },
    {
        path:'/todayproblem',
        element: TodayProblemList,
        required: true
    },
    {
        path:'/todayproblem/:proID',
        element: TodayProblem,
        required: true
    },
    // {
    //     path:'/todayproblem/my/:proIDMy',
    //     element: TodayProblemMy
    // },
    // {
    //     path:'/todayproblem/my/animation/:proIDMy',
    //     element: TodayProblemMySolAnimation
    // },
    {
        path:'/improvemathability',
        element: ImproveMathAbilityList,
        required: true
    },
    {
        path:'/improvemathability/:cateProbID',
        element: ImproveMathAbility,
        required: true
    },
    // {
    //     path:'/improvemathability/my/:cateProbIDMy',
    //     element: ImproveMathAbilityMy
    // },
    // {
    //     path:'/improvemathability/my/animation/:cateProbIDMy',
    //     element: ImproveMathAbilityMySolAnimation
    // },
    {
        path:'/mathsubject',
        element: MathSubjectList,
        required: true
    },
    // {
    //     path:'/mathsubject/:firstProbID',
    //     element: MathSubject,
    //     required: true
    // },
    // {
    //     path:'/mathsubject/my/:firstProbIDMy',
    //     element: MathSubjectMy
    // },
    // {
    //     path:'/mathsubject/my/animation/:firstProbIDMy',
    //     element: MathSubjectMySolAnimation
    // },
    {
        path:'/grade/brainup/list/',
        element: GradeTotalListBrainUp,
        required: true
    },
    {
        path:'/grade/textbook/list/',
        element: GradeTotalListTextbook,
        required: true
    },
    {
        path:'/grade/:gradeID',
        element: GradeList,
        required: true
    },
    {
        path:'/gradesubject/:topCategory',
        element: GradeFirstProblem,
        required: true
    },
    {
        path:'/geometriccoloring',
        element: GeometricColoringList,
        required: true
    },
    // {
    //     path:'/geometriccoloring/:geoID',
    //     element: GeometricColoring,
    //     required: true
    // },
    {
        path:'/login',
        element: Login,
        required: true
    },
    {
        path:'/signup',
        element: SignUp,
        required: true
    },
    {
        path:'/freeagreement',
        element: FreeAgreement,
        required: true
    },
    {
        path:'/unauthorized',
        element: Unauthorized,
        required: true
    },
    {
        path:'/forgotid',
        element: ForgotIdFirst,
        required: true
    },
    {
        path:'/forgotid/emailsecond',
        element: ForgotIdSecondEmail,
        required: true
    },
    {
        path:'/forgotid/mobilesecond',
        element: ForgotIdSecondMobile,
        required: true
    },
    {
        path:'/forgotid/third',
        element: ForgotIdThird,
        required: true
    },
    {
        path:'/forgotpassword',
        element: ForgotPwdFirst,
        required: true
    },
    {
        path:'/forgotpassword/second',
        element: ForgotPwdSecond,
        required: true
    },
    {
        path:'/calculationexercise/list',
        element: ExerciseCalculationList,
        required: true
    },
    // {
    //     path:'/calculationexercise/sample/',
    //     element: ExerciseCalculationSample,
    //     required: true
    // },
    {
        path:'/viewimage/:imageFileName/:imageBackgroundColor',
        element: ViewImage,
        required: true
    },
    {
        path:'/todayproblem/image/:todayID',
        element: TodayProblemImage,
        required: true
    },
    {
        path:'/todayproblem/image/list/',
        element: TodayProblemImageList,
        required: true
    },
    {
        path:'/todayproblemfi/:todayID',
        element: TodayProblemFromImage,
        required: true
    },
    {
        path:'/mathsubject/image/list/',
        element: MathSubjectImageList,
        required: true
    },
    {
        path:'/policy/privacypolicy',
        element: ViewPrivacyPolicy,
        required: true
    },
    {
        path:'/policy/freemembershipagreement',
        element: ViewFreemembershipAgreement,
        required: true
    },
    {
        path:'/policy/premiumserviceagreement',
        element: ViewPremiumServiceAgreement,
        required: true
    },
    {
        path:'/policy/copyright',
        element: ViewCopyright,
        required: true
    },
    {
        path:'/door/today/:todayDoorURL',
        element: DoorTodayProblem,
        required: true
    },
    {
        path:'/door/brainup/:brainUpDoorURL',
        element: DoorBrainUp,
        required: true
    },
    {
        path:'/door/problem/:problemURL',
        element: DoorProblem,
        required: true
    }
];